import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light, dark } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'IS_DARK'

export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}

const ThemeContext = React.createContext<ThemeContextType>({ isDark: false, toggleTheme: () => null })

// ALSO CHANGE GLOBAL.ts
const colorOverride = {
  // Change everything up to and inc. textSubtle or secondary
  contrast: "#261f13", 
  input: "#f4eaea",
  inputSecondary: "#eccaca",
  primary: "#d00000",
  primaryBright: "#e95353",
  primaryDark: "#a10000",
  text: "#d00000",
  textSubtle: "#7a2a2a",
  secondary: "#d94545", // purple
  success: "#023020", // green
  background: "#FAF9FA",
  backgroundAlt: "#FFFFFF",
  backgroundDisabled: "#E9EAEB",
  binance: "#F0B90B",
  borderColor: "#E9EAEB",
  dropdown: "#F6F6F6",
  failure: "#ed4b4b",
  gradients: {bubblegum: "linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)", cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)", blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)", violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)", violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)"},
  invertedContrast: "#FFFFFF",
  tertiary: "#EFF4F5",
  textDisabled: "#BDC2C4",
  warning: "#FFB237",
}

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={ {...light, colors:colorOverride} }>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
