import { createGlobalStyle } from 'styled-components'

// THESE MUST BE IN RGB
const primaryColor = `#EFF4F5`; // light color
const strokeColor= "#a16600"; // color to change


const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
    }
    img {
      height: auto;
      max-width: 100%;
    }

    .colorTheme {
      color: ${strokeColor} !important;
    }
    

    .primaryBtn {
      background-color: ${primaryColor} !important;
      color: white;
    }
    
    .tertiaryBtn {
      color: ${primaryColor};
      background-color: #353547;
    }

    svg {
      fill: ${primaryColor} !important;
      color: ${strokeColor};
      stroke: ${strokeColor};
    }

    #token-search-input:focus {
      border-color: ${primaryColor};
    }

    a {
      color: ${strokeColor}; 
      fill: ${strokeColor};
    }

    div[color="primary"], div[color="#1FC7D4"],
    a[variant="primary"] {
      background-color: ${primaryColor};
    }
  }

  ul {
    list-style: none; 
  }

  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: "•";
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
  }

`

export default GlobalStyle
