import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import BrickBg from "assets/images/brickBg.jpg";
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import Mint from './Mint'


import './style.css'

const AppWrapper = styled.div`
`

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  height:100vh;
  width: 100vw;
  background-image: url(${BrickBg});
  background-size: cover;
  background-repeat: no-repeat;
`

export default function App() {
  return (
    <AppWrapper style={{ overflow: 'hidden' }}>
      <Suspense fallback={null}>
        <HashRouter>
          <Popups />
          <BodyWrapper>
            <Web3ReactManager>
              <>
                {/* TODO REMOVE THIS DISABLE  */}
                {/* <Swap/> */}
                <Mint/>
              </>
            </Web3ReactManager>
          </BodyWrapper>
        </HashRouter>
      </Suspense>
    </AppWrapper>
  )
}

