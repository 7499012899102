import React from "react";
import { Button, ButtonProps, useModal } from "@pancakeswap-libs/uikit";
import useAuth from "../../hooks/useAuth";
import ConnectModal from './ConnectModal'

const ConnectWalletButton: React.FC<ButtonProps & { label?: string }> = (props) => {
  const { login, logout } = useAuth();
  // const { onPresentConnectModal } = useWalletModal(login, logout);
  const [onPresentConnectModal] = useModal(
    <ConnectModal
    />
  )
  const { label } = props;

  return (
    <Button className="connect-wallet" onClick={onPresentConnectModal} {...props}>
      {label ?? "Connect Wallet to Proceed"}
    </Button>
  );
};

export default ConnectWalletButton;
