import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import GlobalStyle from './style/Global'
import App from './pages/App'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import ToastListener from './components/ToastListener'
import Providers from './Providers'
import 'inter-ui'
import './i18n'

if ('ethereum' in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false
}

window.addEventListener('error', () => {
   localStorage?.removeItem('redux_localstorage_simple_lists')
})

// // CONSTANTS FOR CONFIGURATION
// const COIN = {
//   "name": "DivToken",
//   "symbol": "DIVTOK",
//   "address": "0xF34577F013287C614694372ca892A4E05CAD68Ad",
//   "chainId": 56,
//   "decimals": 18,
//   "logoURI": "https://greenwojak.finance/images/Green-Wojak-Logo.png"
// }

ReactDOM.render(
    <StrictMode>
      <Providers>
        <>
          <ListsUpdater />
          <ApplicationUpdater />
          <TransactionUpdater />
          <MulticallUpdater />
          <ToastListener />
        </>
        {/* <ResetCSS /> */}
        <GlobalStyle />
        <App />
      </Providers>
    </StrictMode>,
  document.getElementById('root')
)
