import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import Countdown from 'react-countdown'
import styled, { keyframes } from 'styled-components'
import { CardBody, Button, Text, LinkExternal, Flex, Heading } from '@pancakeswap-libs/uikit'
import { Wrapper } from 'components/swap/styleds'
import { QuantityPicker } from 'react-qty-picker'
import { useActiveWeb3React } from 'hooks'
import useToast from 'hooks/useToast'
import ConnectWalletButton from 'components/ConnectWalletButton'
import * as NftCollection from 'config/abis/NftContract.json'
import { useContract } from '../../hooks/useContract'
import privateWhitelist from './private_signed.json'
import communityWhitelist from './community_signed.json'

const EXPIRY_BUFFER = 2e3 // 2 seconds
const COLLECTION_URI = 'https://opensea.io/account'

const animateLightBar = keyframes`
	0%,5% {
		transform: scaleY(0) ;
	}
    10%{
        transform: scaleY(1) ;
    }
	90% {
        transform: scaleY(1) ;
	}
	95%,100% {
        transform: scaleY(0) ;
	}
`
const TextBoxAnimation = keyframes`
   0%,5% {
		transform: translate3d(-80vw,0,0);
	}
    10%{
        transform:  translate3d(-80vw,0,0);
    }
	90% {
        transform:  translate3d(0,0,0);
	}
	95%,100% {
        transform: translate3d(0,0,0);
	}
`
const TextAnimation = keyframes`
    0%,5% {
		transform:  translate3d(80vw,0,0);
	}
    10%{
        transform:  translate3d(80vw,0,0);
    }
	90% {
        transform:  translate3d(0,0,0);
	}
	95%,100% {
        transform: translate3d(0,0,0);
	}
`
const BorderShow = keyframes`
    0%{
		opacity:0;
	}
   
	95% {
        opacity:0;
	}
    100%{
        opacity:1;
    }
`
const Container = styled.div`
  position: relative;
  padding: 3rem;
  @media only screen and (max-width: 500px) {
    padding: 3rem 0 0 0;
  }
`
const W = styled.div`
  margin-top: 30px;
  position: relative;
  top: 10%;
  left: 10%;
  width: 80%;
  font-size: 2rem;
  background-color: #04d9ff24;
  border-radius: 10px;
  z-index: 3;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 0 0.1em #04d9ff, inset 0 0 0.2em #04d9ff, inset 0 0 0.5em #04d9ff, 0 0 4em #04d9ff;
    animation: ${BorderShow} 2s linear forwards;
    border: 5px solid #fff;
    border-radius: inherit;
  }
  @media only screen and (max-width: 500px) {
    top: 15%;
  }
  @media only screen and (min-width: 568px) {
    font-size: 3.5rem;
  }
  @media only screen and (min-width: 768px) {
    font-size: 5rem;
  }
`
const TextBox = styled.div`
  overflow: hidden;
  width: 100%;
  /* transform: translate(300px,0); */
  animation: ${TextBoxAnimation} 2s linear forwards;
  padding: 1rem 1rem;
`
const H1 = styled.h1`
  text-align: center;
  font-size: inherit;
  color: #fff;
  text-shadow: 0 0 0.2em #ff3333, 0 0 0.3em #ff3333, 0 0 0.5em #ff3333, 0 0 8.8em #ff3333, 0 0 8.6em #ff3333;
  animation: ${TextAnimation} 2s linear forwards;
`
const LightBar = styled.div`
  position: relative;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 0 10px #04d9ff, 0 0 20px #04d9ff, 0 0 40px #04d9ff, 0 0 80px #04d9ff, 0 0 120px #04d9ff;
  animation: ${animateLightBar} 2s linear forwards;
`

const P = styled.p`
  font-size: 0.3em;
  line-height: 1;
  color: #fff;
  text-shadow: 0 0 0.1em #ff3333, 0 0 0.2em #ff3333, 0 0 0.4em #ff3333, 0 0 0.8em #ff3333, 0 0 1.6em #ff3333;
  animation: ${TextAnimation} 2s linear forwards;
  text-align: center;
`

// TODO: Change this as needed
export const MAIN_NFT_MINT_START_TIMES = {
  privateWhitelistTime: new Date(1651280400 * 1e3),
  privateWhitelistEnd: new Date(1651283916 * 1e3),
  communityWhitelistTime: new Date(1651283916 * 1e3),
  communityWhitelistEnd: new Date(1651283926 * 1e3),
  publicMintTime: new Date(1651283936 * 1e3),
}

const nftAddressByChain = {
  1: '0xfdd1148c433ae4e25522a2a0479f21eb43ac2f15',
  4: '0x65864ea32dec83cfdff16a67172b6d33f1e91c3d',
}

const Mint = () => {
  const { account, chainId } = useActiveWeb3React()
  const nftContract = useContract(nftAddressByChain[chainId || '1'], NftCollection.abi, true)

  const { privateWhitelistTime, publicMintTime, privateWhitelistEnd, communityWhitelistTime, communityWhitelistEnd } =
    MAIN_NFT_MINT_START_TIMES

  const [state, setState] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [balanceOf, setBalanceOf] = useState(0)
  const [renderKey, setRenderKey] = useState(0)
  const { toastSuccess, toastError } = useToast()

  const [qty, setQty] = useState(1)
  const [maxElements, setMaxElements] = useState(515)
  const [totalSupply, setTotalSupply] = useState(515)

  const [whitelistDetails, setWhitelistDetails] = useState<any>()

  const [privateWlNotExpired, setPrivateWlNotExpired] = useState(true)
  const [startTime, setStartTime] = useState(publicMintTime)
  const [whitelistMintClaimed, setWhitelistMintClaimed] = useState(false)
  const [whitelistType, setWhitelistType] = useState<any>('none')
  const [endTime, setEndTime] = useState(privateWhitelistEnd)

  useEffect(() => {
    const timeNow = new Date()
    const _privateWlNotExpired = Number(timeNow) < Number(privateWhitelistEnd) - EXPIRY_BUFFER
    const _communityWlNotExpired = Number(timeNow) < Number(communityWhitelistEnd) - EXPIRY_BUFFER
    setPrivateWlNotExpired(_privateWlNotExpired || _communityWlNotExpired)

    const loadData = async () => {
      if (account) {
        const acctAddr = account.toLowerCase()
        try {
          let privateWhitelistDetails
          let communityWhitelistDetails

          if (_privateWlNotExpired || _communityWlNotExpired) {
            // @ts-ignore
            privateWhitelistDetails = privateWhitelist[acctAddr]
            communityWhitelistDetails = communityWhitelist[acctAddr]
          }

          let activeCountdownTime = publicMintTime
          if (!!privateWhitelistDetails && _privateWlNotExpired) {
            activeCountdownTime = privateWhitelistTime
            setWhitelistDetails(privateWhitelistDetails)
            const _whitelistMintClaimed = await nftContract?.callStatic.privateMintClaimed(1, account)
            setWhitelistMintClaimed(_whitelistMintClaimed)
            setWhitelistType('private')
            setEndTime(privateWhitelistEnd)
          } else {
            setWhitelistType('none')
          }

          setStartTime(activeCountdownTime)
          if (Number(timeNow) < Number(activeCountdownTime)) {
            setIsActive(false)
          }

          const tokensOwned = await nftContract?.callStatic.balanceOf(account)
          setBalanceOf(Number(tokensOwned) || 0)
        } catch (e) {
          console.log('error is', e)
          console.log('params were: ', nftContract, account)
        }
      }
      try {
        const totSupply = await nftContract?.callStatic.totalSupply()
        console.log('maxEle', 'totSupply', Number(totSupply))
        setMaxElements(Number(2200))
        setTotalSupply(Number(totSupply))
      } catch (e) {
        console.log('error getting max and total supply', e)
      }
    }
    loadData()
  }, [
    account,
    chainId,
    nftContract,
    renderKey,
    privateWhitelistTime,
    publicMintTime,
    privateWhitelistEnd,
    communityWhitelistEnd,
    communityWhitelistTime,
  ])

  const _getPrice = (_qty) => _qty === 10 ? BigInt(0.045e18 * _qty) : BigInt(_qty * 0.06e18);

  const handleMint = async () => {
    setState('loading')
    const cost = _getPrice(qty)

    try {
      let tx
      if (whitelistDetails && account && privateWlNotExpired) {
        const acctAddr = account.toLowerCase()
        // @ts-ignore
        const { whitelistNonce, messageHash, v, r, s } = whitelistDetails
        const bufR = Buffer.from(r)
        const bufS = Buffer.from(s)
        console.log("cost", cost.toString())
        tx = await nftContract?.whitelistMint(qty, whitelistNonce, messageHash, v, bufR, bufS, {
          value: cost.toString(),
        })
      } else {
        tx = await nftContract?.mint(qty, { value: cost.toString() })
      }
      const message = await tx.wait(1)
      setState('success')
      toastSuccess('Mint Successful!')
    } catch (e: any) {
      console.log('error', e)
      setState('error')
      toastError('Error', `${e.reason || e.message}`)
    }
    setRenderKey(Math.random())
  }
  const handleQtyChange = (val) => {
    setQty(val)
  }


  return (
    <>
      <W>
        <TextBox>
          <H1>
            Rappears <br /> DAO
          </H1>
          <br />
          <P>
            Rappear holders share profits from trade and music royalties
            <br />
            Mint yours before they&apos;re gone.
          </P>
          <LightBar />
        </TextBox>
      </W>

      <Wrapper id="swap-page" style={{ padding: 5, display: 'flex', flexDirection: 'column', top: '20%' }}>
        {account ? (
          <>
            {!isActive && (
              <Countdown
                date={startTime}
                onMount={({ completed }) => {
                  if (completed) {
                    setIsActive(true)
                  }
                }}
                onComplete={() => setIsActive(true)}
                renderer={(args) => renderCounter(args, whitelistType)}
              />
            )}

            {isActive && (
              <>
                {privateWlNotExpired && whitelistDetails && (
                  <InfoText>
                    CHOOSE WISELY <br />{' '}
                    <span style={{ fontSize: 18 }}>You&apos;re permitted one transaction during this round.</span>
                  </InfoText>
                )}
                <QuantityPicker smooth max={200} value={qty} min={1} onChange={handleQtyChange} />

                <InfoText style={{marginTop: 10}}>
                Total: {(Number(_getPrice(qty))/1e18).toFixed(2)} ETH *
                </InfoText>
                <InfoText style={{fontSize: 12, marginBottom:10}}>
                ** 0.06 ETH each or 0.045 ETH each for 10
                </InfoText>

              </>
            )}

            <Button
              onClick={handleMint}
              id="claim-button"
              disabled={!isActive || state === 'loading' || maxElements === totalSupply || whitelistMintClaimed}
              width="200px"
            >
              {state === 'loading'
                ? 'Awaiting Wallet Confirmation...'
                : privateWlNotExpired && whitelistDetails
                ? whitelistMintClaimed
                  ? `Whitelist already claimed`
                  : `Whitelist Mint ${qty}`
                : `Mint ${qty} NFTs`}
            </Button>
            {balanceOf > 0 && <LinkExternal href={COLLECTION_URI}>{balanceOf} NFTs are in your wallet </LinkExternal>}
            {isActive && (
              <Countdown
                date={Number(endTime)}
                onComplete={() => setRenderKey(Math.random())}
                renderer={renderExpiryCounter}
              />
            )}
          </>
        ) : (
          <>
            <br />
            <ConnectWalletButton label="Connect Wallet to Mint" />
          </>
        )}
        {!!(Number(maxElements) - Number(totalSupply)) && (
          <RemainingItemsText>
            {Number(maxElements) - Number(totalSupply)} of {maxElements} remaining
          </RemainingItemsText>
        )}
      </Wrapper>
    </>
  )
}

const InfoText = styled.div`
  font-size: 15px;
  color: white;
  text-align: center;
`

const RemainingItemsText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary}
  padding: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
`

const SubText = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 10px;
`

const renderCounter = ({ days, hours, minutes, seconds, completed }: any, whitelistType: string) => {
  if (completed) {
    return <></>
  }
  let infoText = 'Public sale starts in '
  if (whitelistType === 'private') {
    infoText = 'You are private whitelisted. Mint starts in '
  } else if (whitelistType === 'community') {
    infoText = 'You are community whitelisted. Mint starts in '
  }
  return (
    <InfoText>
      {infoText}
      <br />
      <br />
      {hours + (days || 0) * 24} hr {minutes} min {seconds} secs
      <br />
      <br />
    </InfoText>
  )
}

const renderExpiryCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return completed ? (
    <></>
  ) : (
    <SubText>
      Current Round Expires in {hours + (days || 0) * 24} hr {minutes} min {seconds} secs
      <br />
    </SubText>
  )
}

const shortenAccount = (account: string) => {
  return `${account.slice(0, 6)}...${account.slice(account.length - 4, account.length)}`
}

export default Mint
